import { MenuEntry } from '@pancakeswap-libs/uikit'
import contracts from 'config/constants/contracts'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Buy',
        href: '/buy',
      },
      {
        label: 'Liquidity',
        href: '/liquidity',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Payrole Pool',
    icon: 'CoinIcon',
    href: '/goldpool',
  },
  {
    label: 'Diamond Pool',
    icon: 'DiamondIcon',
    href: '/diamondpool',
  },
  // {
  //   label: 'Jungle Pools',
  //   icon: 'PoolIcon',
  //   href: '/junglepools',
  // },
  {
    label: 'NFT Gallery',
    icon: 'NftIcon',
    href: '/nftgallery',
  },
  {
    label: 'Launchpad (IDO)',
    icon: 'IfoIcon',
    href: '/ido',
  },
  // {
  //   label: 'King of Elephants',
  //   icon: 'NftIcon',
  //   href: '/kingofelephants',
  // },
  {
    label: 'Charts',
    icon: 'ChartIcon',
    items: [
      {
        label: 'QuickSwap Chart',
        href: `https://info.quickswap.exchange/token/${contracts.cake[process.env.REACT_APP_CHAIN_ID]}`,
        target: '_blank',
      },
      {
        label: 'DFYN Chart',
        href: `https://info.dfyn.network/token/${contracts.cake[process.env.REACT_APP_CHAIN_ID]}`,
        target: '_blank',
      },
      {
        label: 'SUSHI Chart',
        href: `https://analytics-polygon.sushi.com/tokens/${contracts.cake[process.env.REACT_APP_CHAIN_ID]}`,
        target: '_blank',
      },
      {
        label: 'PooCoin',
        href: `https://polygon.poocoin.app/tokens/${contracts.cake[process.env.REACT_APP_CHAIN_ID]}`,
        target: '_blank',
      },
      {
        label: 'QuickChart',
        href: `https://quickchart.app/token/${contracts.cake[process.env.REACT_APP_CHAIN_ID]}`,
        target: '_blank',
      },
      {
        label: 'Polychart',
        href: `https://app.polychart.io/explorer/polygon/${contracts.cake[process.env.REACT_APP_CHAIN_ID]}`,
        target: '_blank',
      },
    ]
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Polygon Explorer',
        href: `https://polygonscan.com/token/${contracts.cake[process.env.REACT_APP_CHAIN_ID]}`,
        target: '_blank',
      },
      {
        label: 'vFat Tool',
        href: `https://vfat.tools/polygon/bullionfinance/`,
        target: '_blank',
      },
      // {
      //   label: 'Dapp Radar',
      //   href: 'https://dappradar.com/polygon/defi/PolyBullion-finance',
      // },
      // {
      //   label: 'Dapp.com',
      //   href: 'https://www.dapp.com/app/PolyBullion-network',
      // },
      {
        label: 'Docs',
        href: 'https://bullion-finance.gitbook.io/bullionfinance',
        target: '_blank',
      },
    ],
  },
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/PolyBullion',
  //     },
  //     {
  //       label: 'Blog',
  //       href: 'https://PolyBullion.medium.com',
  //     },
  //     {
  //       label: 'Docs',
  //       href: 'https://docs.PolyBullion.finance/',
  //     },
  //   ],
  // },
  // {
  //   label: 'Roadmap',
  //   icon: 'RoadmapIcon',
  //   href: 'https://docs.PolyBullion.finance/roadmap',
  // },
  // {
  //   label: 'Collab',
  //   icon: 'HandshakeIcon',
  //   href: 'https://t.me/PolyBullionhead',
  // },
]

export default config
