import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 30,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'USD Coin',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f'
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f'
    },
  },
  {
    pid: 18,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Knit Finance',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xa0EEDa2e3075092d66384fe8c91A1Da4bcA21788'
    },
    tokenSymbol: 'KFT',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xa0EEDa2e3075092d66384fe8c91A1Da4bcA21788'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xa0EEDa2e3075092d66384fe8c91A1Da4bcA21788'
    },
  },
  {
    pid: 32,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'USD K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x472402d47Da0587C1cf515DAfbAFc7bcE6223106'
    },
    tokenSymbol: 'USDk',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x472402d47Da0587C1cf515DAfbAFc7bcE6223106'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x472402d47Da0587C1cf515DAfbAFc7bcE6223106'
    },
  },
  // {
  //   pid: 31,
  //   risk: 5,
  //   isTokenOnly: false,
  //   lpSymbol: 'USDC K',
  //   lpAddresses: {
  //     97: '',
  //     56: '',
  //     137: '',
  //     2222: '0xfC929Fd8311E1cC16801dC12e14233Eecd272A29'
  //   },
  //   tokenSymbol: 'kUSDC',
  //   tokenAddresses: {
  //     97: '',
  //     56: '',
  //     137: '',
  //     2222: '0xfC929Fd8311E1cC16801dC12e14233Eecd272A29'
  //   },
  //   quoteTokenSymbol: QuoteToken.KNIT,
  //   quoteTokenAdresses: {
  //     97: '',
  //     56: '',
  //     137: '',
  //     2222: '0xfC929Fd8311E1cC16801dC12e14233Eecd272A29'
  //   },
  // },
  {
    pid: 24,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Brise K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xEA616011E5AC9a5b91e22CaC59b4eC6f562b83f9'
    },
    tokenSymbol: 'kBRISE',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xEA616011E5AC9a5b91e22CaC59b4eC6f562b83f9'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xEA616011E5AC9a5b91e22CaC59b4eC6f562b83f9'
    },
  },
  {
    pid: 0,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Ethereum K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x5C23409ed30f8Ec851cb9c543BbC7AA22F820403'
    },
    tokenSymbol: 'kETH',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x5C23409ed30f8Ec851cb9c543BbC7AA22F820403'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x5C23409ed30f8Ec851cb9c543BbC7AA22F820403'
    },
  },
  {
    pid: 1,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Bitcoin K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xBB2EcE2BBCD591867F9F994bfDfEfd563e2CEDc7'
    },
    tokenSymbol: 'kBTC',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xBB2EcE2BBCD591867F9F994bfDfEfd563e2CEDc7'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xBB2EcE2BBCD591867F9F994bfDfEfd563e2CEDc7'
    },
  },
  {
    pid: 2,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Litecoin K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x72F7CB3e98b34106e5B5801F3be510F6Da8fea6D'
    },
    tokenSymbol: 'kLTC',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x72F7CB3e98b34106e5B5801F3be510F6Da8fea6D'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x72F7CB3e98b34106e5B5801F3be510F6Da8fea6D'
    },
  },
  {
    pid: 3,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Cardano K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x362cbbe5f4c3178DbCe858F3544b653Fc00B8B0d'
    },
    tokenSymbol: 'kADA',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x362cbbe5f4c3178DbCe858F3544b653Fc00B8B0d'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x362cbbe5f4c3178DbCe858F3544b653Fc00B8B0d'
    },
  },
  {
    pid: 4,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Polygon K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x8c765a721301BB4B973512c1197F778b9ABaDF20'
    },
    tokenSymbol: 'kMATIC',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x8c765a721301BB4B973512c1197F778b9ABaDF20'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x8c765a721301BB4B973512c1197F778b9ABaDF20'
    },
  },
  {
    pid: 5,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Avalanche K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xFbbbCdf97e021bCadD93DB8Dc4Afafa0814c376E'
    },
    tokenSymbol: 'kAVAX',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xFbbbCdf97e021bCadD93DB8Dc4Afafa0814c376E'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xFbbbCdf97e021bCadD93DB8Dc4Afafa0814c376E'
    },
  },
  {
    pid: 6,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'IOST K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x84d600ef31246C4574Ea8A07C126228eC57FAEb7'
    },
    tokenSymbol: 'kIOST',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x84d600ef31246C4574Ea8A07C126228eC57FAEb7'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x84d600ef31246C4574Ea8A07C126228eC57FAEb7'
    },
  },
  {
    pid: 7,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'DASH K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x19D5E7D84fE8fc4AfdFE4764C1d7d07Fb2182435'
    },
    tokenSymbol: 'kDASH',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x19D5E7D84fE8fc4AfdFE4764C1d7d07Fb2182435'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x19D5E7D84fE8fc4AfdFE4764C1d7d07Fb2182435'
    },
  },
  {
    pid: 8,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Ripple K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x8bE6757129c895E05dee403DD1295682a3fE77F1'
    },
    tokenSymbol: 'kXRP',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x8bE6757129c895E05dee403DD1295682a3fE77F1'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x8bE6757129c895E05dee403DD1295682a3fE77F1'
    },
  },
  {
    pid: 9,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Stellar K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x543374C96196Df7B021889541a66ce9c9105Ee3A'
    },
    tokenSymbol: 'kXLM',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x543374C96196Df7B021889541a66ce9c9105Ee3A'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x543374C96196Df7B021889541a66ce9c9105Ee3A'
    },
  },
  {
    pid: 10,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Dogecoin K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x1e2e5CcB972C3BB08D49d6d435eE567eef9e6629'
    },
    tokenSymbol: 'kDOGE',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x1e2e5CcB972C3BB08D49d6d435eE567eef9e6629'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x1e2e5CcB972C3BB08D49d6d435eE567eef9e6629'
    },
  },
  {
    pid: 11,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'BitcoinCash K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x0f3F5cfA49b7C752A721d72A17A745d0228F940A'
    },
    tokenSymbol: 'kBCH',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x0f3F5cfA49b7C752A721d72A17A745d0228F940A'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x0f3F5cfA49b7C752A721d72A17A745d0228F940A'
    },
  },
  {
    pid: 12,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Zcash K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x1bcC64Fbb764ccFb25f946d900Cfb4618F7F9CFE'
    },
    tokenSymbol: 'kZEC',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x1bcC64Fbb764ccFb25f946d900Cfb4618F7F9CFE'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x1bcC64Fbb764ccFb25f946d900Cfb4618F7F9CFE'
    },
  },
  {
    pid: 14,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'BitcoinSV K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x16FE41843d3CA657bbFD28e944532f5aAE376d69'
    },
    tokenSymbol: 'kBSV',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x16FE41843d3CA657bbFD28e944532f5aAE376d69'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x16FE41843d3CA657bbFD28e944532f5aAE376d69'
    },
  },
  {
    pid: 15,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Frontier K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xF3989164829dF113f8806af778cD101b499Fc554'
    },
    tokenSymbol: 'kFRONT',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xF3989164829dF113f8806af778cD101b499Fc554'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xF3989164829dF113f8806af778cD101b499Fc554'
    },
  },
  {
    pid: 16,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Fantom K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfb0E0E53Bd9b9D067FE5D2B0e116256EB77df5D4'
    },
    tokenSymbol: 'kFTM',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfb0E0E53Bd9b9D067FE5D2B0e116256EB77df5D4'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfb0E0E53Bd9b9D067FE5D2B0e116256EB77df5D4'
    },
  },
  {
    pid: 17,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'LoomNetwork K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x087665054b3E9fE96d4D289ff2Fb1CB5397D5AbC'
    },
    tokenSymbol: 'kLOOM',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x087665054b3E9fE96d4D289ff2Fb1CB5397D5AbC'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x087665054b3E9fE96d4D289ff2Fb1CB5397D5AbC'
    },
  },
  {
    pid: 20,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Algorand K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x58B95953bF04B89C9BBe3D3B1afE0fCB11B73e14'
    },
    tokenSymbol: 'kALGO',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x58B95953bF04B89C9BBe3D3B1afE0fCB11B73e14'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x58B95953bF04B89C9BBe3D3B1afE0fCB11B73e14'
    },
  },
  {
    pid: 21,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Gemini Dollar K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x574325886e1A5622585E4dC9D9395Bfa8316054E'
    },
    tokenSymbol: 'kGUSD',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x574325886e1A5622585E4dC9D9395Bfa8316054E'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x574325886e1A5622585E4dC9D9395Bfa8316054E'
    },
  },
  {
    pid: 22,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Origin Protocol K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x916834A4C04807A68B5e0A23e44A14e92F4B3Ca0'
    },
    tokenSymbol: 'kOGN',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x916834A4C04807A68B5e0A23e44A14e92F4B3Ca0'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x916834A4C04807A68B5e0A23e44A14e92F4B3Ca0'
    },
  },
  {
    pid: 23,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'KyberNetwork K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xEE0Ed1EA60652bD36f3eaAA54248FF742f8E4311'
    },
    tokenSymbol: 'kKNC',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xEE0Ed1EA60652bD36f3eaAA54248FF742f8E4311'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xEE0Ed1EA60652bD36f3eaAA54248FF742f8E4311'
    },
  },
  {
    pid: 25,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'NINJAINU K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xDD4005B3687f4441c396377c457bED9f8E016415'
    },
    tokenSymbol: 'kNIN',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xDD4005B3687f4441c396377c457bED9f8E016415'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xDD4005B3687f4441c396377c457bED9f8E016415'
    },
  },
  {
    pid: 26,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Plueto Finance K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x31f4099BC3867C167029f7e9eC3CD70E805C9438'
    },
    tokenSymbol: 'kPLTO',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x31f4099BC3867C167029f7e9eC3CD70E805C9438'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x31f4099BC3867C167029f7e9eC3CD70E805C9438'
    },
  },
  {
    pid: 27,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Akuilla Finance K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x778bed24D490d5802fa2F325294Eca2191B73Cf7'
    },
    tokenSymbol: 'kAQA',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x778bed24D490d5802fa2F325294Eca2191B73Cf7'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x778bed24D490d5802fa2F325294Eca2191B73Cf7'
    },
  },
  {
    pid: 28,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Peaches K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x410e72a391052B9838573CE603c117B3FE80BEB4'
    },
    tokenSymbol: 'kPEA',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x410e72a391052B9838573CE603c117B3FE80BEB4'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x410e72a391052B9838573CE603c117B3FE80BEB4'
    },
  },
  {
    pid: 29,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'SHIB K',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x0b6ada716BE4C41fA8a223d17EA2c39a0E154E89'
    },
    tokenSymbol: 'kSHIB',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x0b6ada716BE4C41fA8a223d17EA2c39a0E154E89'
    },
    quoteTokenSymbol: QuoteToken.KNIT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0x0b6ada716BE4C41fA8a223d17EA2c39a0E154E89'
    },
  },
]

export default farms
